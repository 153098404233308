import { Fragment, useContext, useEffect, useState } from "react"
import "../css/Cliente.css"
import FacebookLogin from "react-facebook-login";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersContext } from "../context/UsersContext";
import { useAuth0 } from "@auth0/auth0-react";


const Cliente = () => {
    const [userLogged, setUserLogged] = useState()
    const [nuevoRegistro, setNuevoRegistro] =useState()
    const {datosUsuariosAdmin, getUsuarios} = useContext(UsersContext)
    const locacion =  useLocation()
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
    let navigate = useNavigate()

    let existe
    const autenticar = () => {
        if(isAuthenticated){
            console.log("login ok")
            existe = datosUsuariosAdmin.filter(usuario => usuario.nombre_admin === user.name && usuario.tipo_login[0] === user.sub[0])
            console.log(existe)
            console.log(user.sub[0])
                if(existe.length){
                    //setLoading(!loading)
                    console.log("el usuario existe")
                    setUserLogged(existe[0])
                    console.log(existe[0].link)
                    //navigate(`/${existe[0].link}/`)
                    document.querySelector(".fondo-iniciar").style.display = "none"
                }else{
                    console.log("el usuario no existe")
                    setNuevoRegistro(user)
                    document.querySelector(".fondo-crear").style.display = "block"
                    if(user.sub[0] === "f"){
                        document.querySelector(".label-correo").style.display = "block"
                        document.querySelector(".correo").style.display = "block"
                    }
                }
            
        }else{
            loginWithRedirect()
        }
    }

    useEffect(() => {
        getUsuarios()
        if(isAuthenticated){
            autenticar()
        }
    },[isLoading])
    
   

    console.log(datosUsuariosAdmin)
    return(
        <Fragment>
          
            <div className="fnd">
                {isLoading?
                    <div className="fondo-cargando">
                      <p>Cargando...</p>
                    </div>:
                    <div className="fondo-iniciar">
                    <button className="btn" onClick={autenticar}>Iniciar sesion</button>
                    </div>
                }

                <div className="fondo-crear" style={{display:"none" }}>
                    <p>¡Aun no tienes cuenta en la aplicacion!</p>
                    <form className="form-usuario" action="#" method="POST" >
                        <p>Ingresa un WhatsApp para tener un mejor contacto</p>
                        <input type="text" className="whatsapp" name="whatsapp" id=""  placeholder="WhatsApp"/>

                        <hr />
                        <button className="btn" onClick="" >Registrarme</button>
                        {user && <p style={{fontSize: "10px"}}>Se creara tu cuenta de Clickynal usuando los datos de tu cuenta de {user.sub[0] === "g"? <p>gmail</p>:user.sub[0] === "f" ? <p>facebook</p>: <p>Apple</p> } <p style={{textDecoration:"underline", color:"blue" }} onClick={logout}>Cambiar cuenta</p></p>}
                    </form>
                </div>
            </div>
             
            
        </Fragment>
    )

}

export default Cliente;