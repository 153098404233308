import { Fragment, useEffect, useState } from "react"
import "../css/AdminDashBoard.css"

const AdminDashBoard = () => {
    const urlfetch = "/citas/php/consultaDirecciones.php"
    //const urlfetch = "http:/localhost/citas/php/consultaDirecciones.php"
    const[usuariosAdmin, setUsuariosAdmin] = useState([])
    
    const getUsuarios = () => {
        
        fetch(urlfetch)
        .then(res => res.json())
        .then(data => setUsuariosAdmin(data))
    }

    const editUsuarios = (e) => {
        let x = e.clientX+"px"
        let y = e.clientY+"px"
        
        document.querySelector(".ventana-editar").style.display = " block"
        document.querySelector(".ventana-editar").style.top = y
        document.querySelector(".ventana-editar").style.left = "0px"
        
    }

    useEffect(() =>{
        getUsuarios()
    },[])
    console.log(usuariosAdmin)
    return(
        <Fragment>
            <div className="fondo-adb">
                <h1 className="h1">Usuarios Admin</h1>
                    {usuariosAdmin.map((usuario, index) =>
                        <div className="ficha" id={"ficha"+index}>
                            <div className="botonera-ficha"><button onClick={editUsuarios}>Editar</button></div>
                            <hr />
                            <VentanaEditar/>
                            <p>Nombre: {usuariosAdmin[index].nombre_admin}</p>
                            <p>Correo: {usuariosAdmin[index].correo_admin}</p>
                            <p>Tipo de usuario: {usuariosAdmin[index].tipo_admin}</p>
                            {usuariosAdmin[index].base_de_datos ? <p>Nombre DB: {usuariosAdmin[index].base_de_datos}</p> : <p></p> }
                            {usuariosAdmin[index].link ? <p>Link: {usuariosAdmin[index].link}</p> : <p></p>}
                        </div>
                    )}
            </div>
        </Fragment>
    )
}

const VentanaEditar = () => {
    return(
        <Fragment>
            <div className="ventana-editar" style={{display:"none"}} >

            </div>

        </Fragment>
    )
}
export default AdminDashBoard;