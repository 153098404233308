import { Fragment, useEffect, useState, useContext } from "react"
import {Routes, Route } from "react-router-dom"
import Menu from './Menu.jsx';
import AdminDashBoard from "./AdminDashBoard.jsx";
import Login from "./Login.jsx";
import Cliente from "./Cliente.jsx"
import Politica from "./Politica.jsx"
import Eliminacion from "./Eliminacion.jsx"
import ProtectedRoute from "./ProtectedRoute.jsx";
import { UsersContext } from "../context/UsersContext.jsx";


const Direccionamiento = () => {
    const urlfetch = process.env.REACT_APP_CONSULTA_DIRECCIONES
    //const urlfetch = "http://localhost/citas/php/consultaDirecciones.php"
    const [direcciones, setDirecciones] = useState([])
    const [usuario, setUsuario] = useState(true)


    const getDirecciones = () => {
        fetch(urlfetch)
        .then(res => res.json())
        .then(data => setDirecciones(data))
    }

    useEffect(()=> {
        getDirecciones();
    },[])
    console.log(direcciones)

        return(
            <Fragment>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="/login/" element={<Login/>} />
                    <Route path="/admin/" element={
                        <ProtectedRoute>
                            <AdminDashBoard/>
                        </ProtectedRoute>
                    } />
                    {direcciones.map((direccion, index) => 
                        <>
                            <Route  
                                path={`/${direcciones[index].link}/*`} 
                                element={
                                    <ProtectedRoute correo_admin={direcciones[index].correo_admin}>
                                        <Menu 
                                            nombre_admin={direcciones[index].nombre_admin} 
                                            correo_admin={direcciones[index].correo_admin} 
                                            link={direcciones[index].link} 
                                        />
                                    </ProtectedRoute>
                                } 
                            />
    
                        </>
    
                    )}
                    <Route path="/politica/" element={<Politica/>} />
                    <Route path="/eliminacion/" element={<Eliminacion/>} />
                </Routes>
    
            </Fragment>
        )
    

}
export default Direccionamiento;