import { Fragment, useState, useEffect} from "react";
import '../css/Disponibilidad.css'




function Disponibilidad({link}){
    const urlfetch1 = process.env.REACT_APP_CONSULTA_ESPACIOS
    const urlfetch2 = process.env.REACT_APP_ACTUALIZAR_CITAS_DISPONIBLES
    const urlfetch3 = process.env.REACT_APP_GUARDAR_CITAS_DISPONIBLES
    const date = new Date()
    //Calcula cuando el año es biciesto------------------------------------------------------------------------------------
    let feb
    let febn
    let calc = date.getFullYear() %4
    if(calc !== 0){
      feb=28
      febn=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]
    }else{
      feb=29
      febn=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]
    }
    const [loading, setLoading] = useState(false)
    const [primerdiames, setPrimerdiames] = useState(new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"1"))
    const [mesamostrar, setMesamostrar] = useState("")
    const [diadelasemana, setDiadelasemana] = useState(0)
    //const[fechasDisponibles , setFechasDisponibles] = useState([{"0":"1","ID":"1","1":"2024-2-11","fechaid":"2024-2-11","2":"09,12,14","horas":"09,12,14","3":"1","activo":"1"},{"0":"2","ID":"2","1":"2024-2-12","fechaid":"2024-2-12","2":"09,12,14,16","horas":"09,12,14,16","3":"1","activo":"1"},{"0":"3","ID":"3","1":"2024-3-5","fechaid":"2024-3-5","2":"09,14","horas":"09,14","3":"1","activo":"1"}])
    const [fechasDisponibles, setFechasDisponibles] = useState([])
    const treinta = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
    const treintayuno = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
    const diasmeses = [treintayuno,febn,treintayuno,treinta,treintayuno,treinta,treintayuno,treintayuno,treinta,treintayuno,treinta,treintayuno];
    const URL = "http://localhost/citas/php/consultaespacios.php"
    const[btniodia, setBtniodia] = useState(false) 
    
    
    //cambia el mes seleccionado
    const actualizarMes = (fecha1, operacion)=>{
        if(operacion === "suma"){
            fecha1.setMonth(fecha1.getMonth() + 1)
            setPrimerdiames(fecha1)
            console.log(primerdiames)
        }else if(operacion === "resta"){
            fecha1.setMonth(fecha1.getMonth() - 1)
            setPrimerdiames(fecha1)
            console.log(primerdiames)
        }
        mesNumeroLetra(primerdiames.getMonth())  
        actualizarDiadelasemana()
        limpiarcheckbox()
    }
    
    //debuelve el mes en letra de acuardo al numero pasado por la prop
    const mesNumeroLetra = (num) => {
        let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto","Septiembre","Octubre","Nobiembre","Diciembre"];
        setMesamostrar(meses[num])
    }
    //setea el dia de la semana en que empieza el mes
    const actualizarDiadelasemana = () => {
        setDiadelasemana(primerdiames.getDay()) 
    }
    //recorre el primer dia al dia de la semana correcto
    const recorrerDia = () => {
            document.getElementById(primerdiames.getFullYear()+"-"+(primerdiames.getMonth()+1)+"-"+"1").setAttribute("class","d"+diadelasemana)
            document.getElementById(primerdiames.getFullYear()+"-"+(primerdiames.getMonth()+1)+"-"+"1").classList.add("button")
            colorActivo()
        }
    //muestra los datos de las citas
    const mostrarDatos = (event) => {
        console.log(event.target.parentElement)
        limpiarseleccionado()
        colorActivo()
        if(document.getElementById(event.target.id).classList.contains("activo")){
            document.getElementById(event.target.id).classList.replace("activo","selected")
        }
        document.getElementById(event.target.id).classList.replace("button","selected")
        if(event){  
            let idf = event.target.id
            console.log(idf)
            let filtro = fechasDisponibles.filter(fecha => fecha.fechaid === idf)
            if(filtro.length === 1 && filtro[0].activo === "1"){
                
                document.querySelector(".div-form").style.display = "block"
                document.querySelector(".form-citas").setAttribute("id","form-citas"+event.target.id)
                document.querySelector(".fechaid").value = event.target.id
                document.querySelector(".diaIO").checked = parseInt(filtro[0].activo)
                document.querySelector(".cita1").checked = parseInt(filtro[0].hora1)
                document.querySelector(".cita2").checked = parseInt(filtro[0].hora2)
                document.querySelector(".cita3").checked = parseInt(filtro[0].hora3)
                document.querySelector(".cita4").checked = parseInt(filtro[0].hora4)
                document.querySelector(".cita5").checked = parseInt(filtro[0].hora5)
                document.querySelector(".cita6").checked = parseInt(filtro[0].hora6)
                document.querySelector(".cita7").checked = parseInt(filtro[0].hora7)
                
                document.querySelector(".label").style.display = "block"
                document.querySelector(".label1").style.display = "block"
                document.querySelector(".label2").style.display = "block"
                document.querySelector(".label3").style.display = "block"
                document.querySelector(".label4").style.display = "block"
                document.querySelector(".label5").style.display = "block"
                document.querySelector(".label6").style.display = "block"
                document.querySelector(".label7").style.display = "block"
         
          
            }else{
                document.querySelector(".div-form").style.display = "block"
                document.querySelector(".form-citas").setAttribute("id","form-citas"+event.target.id)
                document.querySelector(".fechaid").value = event.target.id
                
                
                document.querySelector(".label1").style.display = "none"
                document.querySelector(".label2").style.display = "none"
                document.querySelector(".label3").style.display = "none"
                document.querySelector(".label4").style.display = "none"
                document.querySelector(".label5").style.display = "none"
                document.querySelector(".label6").style.display = "none"
                document.querySelector(".label7").style.display = "none"
            
                limpiarcheckbox()
            }
        }
       
        
    }
   
    //Limpia los dias seleccionados al cambiar de mes
    const limpiarxmes = () =>{
        let id = primerdiames.getFullYear()+"-"+(primerdiames.getMonth()+1)
        for(let x=0; x<diasmeses[primerdiames.getMonth()].length;x++){
            document.getElementById(id+"-"+(x+1)).classList.replace("activo","button")
        }
    }
    const limpiarseleccionado = () =>{
        let id = primerdiames.getFullYear()+"-"+(primerdiames.getMonth()+1)
        for(let x=0; x<diasmeses[primerdiames.getMonth()].length;x++){
           
            document.getElementById(id+"-"+(x+1)).classList.replace("selected","button")
        }
    }
    const limpiarcheckbox = () => {
        //document.querySelector(".div-form").style.display = "block"
        document.querySelector(".diaIO").checked = 0
        document.querySelector(".cita1").checked = 0
        document.querySelector(".cita2").checked = 0
        document.querySelector(".cita3").checked = 0
        document.querySelector(".cita4").checked = 0
        document.querySelector(".cita5").checked = 0
        document.querySelector(".cita6").checked = 0
        document.querySelector(".cita7").checked = 0
    }

    //cambia el color del boton del dia de acuerdo a lo guardado en la db
    const colorActivo = () =>{
        limpiarxmes()
        
        for(let x=0; x<fechasDisponibles.length; x++){
            if(primerdiames.getMonth() === new Date(fechasDisponibles[x].fechaid).getMonth()){
                if(fechasDisponibles[x].activo === "1"){
                   
                    document.getElementById(fechasDisponibles[x].fechaid).classList.replace("button","activo")
                }
            }
        }
    }

    


    const actualizar = () =>{
        let prefijo_tabla = new FormData()
        prefijo_tabla.set("link",link)
        
        fetch(urlfetch1,{
            method: 'POST',
            body: prefijo_tabla
        })
          .then(res1 => res1.json())
          .then(data1 => {
            setFechasDisponibles(data1);
            setLoading(!loading)
            console.log("se cargo la base de datos")
            });      
    }

    const actualizarCitas = (e) => {
        let idform = "#"+e.target.parentElement.id
        let form = document.querySelector(idform)
        let datosform = new FormData(form)
        datosform.set("link",link)
        
        if(datosform.get('hora1')){
            datosform.set('hora1', 1)
            
        }else{
            datosform.set('hora1', 0)
            
        }
        if(datosform.get('hora2')){
            datosform.set('hora2', 1)
            
        }else{
            datosform.set('hora2', 0)
            
        }
        if(datosform.get('hora3')){
            datosform.set('hora3', 1)
            
        }else{
            datosform.set('hora3', 0)
           
        }
        if(datosform.get('hora4')){
            datosform.set('hora4', 1)
         
        }else{
            datosform.set('hora4', 0)
         
        }
        if(datosform.get('hora5')){
            datosform.set('hora5', 1)
      
        }else{
            datosform.set('hora5', 0)
           
        }
        if(datosform.get('hora6')){
            datosform.set('hora6', 1)
           
        }else{
            datosform.set('hora6', 0)
     
        }
        if(datosform.get('hora7')){
            datosform.set('hora7', 1)
         
        }else{
            datosform.set('hora7', 0)
        
        }
        if(datosform.get('activo')){
            datosform.set('activo', 1)
            document.querySelector(".label").style.display = "block"
            document.querySelector(".label1").style.display = "block"
            document.querySelector(".label2").style.display = "block"
            document.querySelector(".label3").style.display = "block"
            document.querySelector(".label4").style.display = "block"
            document.querySelector(".label5").style.display = "block"
            document.querySelector(".label6").style.display = "block"
            document.querySelector(".label7").style.display = "block"
            
        }else{
            datosform.set('activo', 0)
            
            document.querySelector(".label1").style.display = "none"
            document.querySelector(".label2").style.display = "none"
            document.querySelector(".label3").style.display = "none"
            document.querySelector(".label4").style.display = "none"
            document.querySelector(".label5").style.display = "none"
            document.querySelector(".label6").style.display = "none"
            document.querySelector(".label7").style.display = "none"
           

        }
        let id = (e.target.parentElement.id).slice(10)
        let filtro = fechasDisponibles.filter(fecha => fecha.fechaid === id)
        if(filtro.length === 1){

           
            fetch(urlfetch2, {
                method: 'POST',
                body: datosform
            })
            .then(res => res.json())
            .then(data => {
                if(data==="hecho"){
                    console.log("se guardaron nuevas citas")
                    actualizar()
                   
                }
            })
        }else{
            guardarNuevasCitas(datosform)
        }
    }
    
    const guardarNuevasCitas = (datosform) => {
        
        fetch(urlfetch3, {
            method: 'POST',
            body: datosform
        })
        .then(res => res.json())
        .then(data => {
            if(data==="hecho"){
                console.log("se actualizo un dia IO")
                actualizar()
                
            }
        })

    }
    
    
    useEffect(()=>{ 
        
        actualizar()
        actualizarMes(primerdiames, "nada")
        recorrerDia()
    },[diadelasemana])

    useEffect(()=>{ 
        colorActivo() 
    },[loading])

    
    return(
        <Fragment>
            <div className="fondo-calendario">
                <div className="botones-calendario">
                    <button onClick={()=>actualizarMes(primerdiames, "resta")}>-</button>
                    <div>{mesamostrar+" "+primerdiames.getFullYear()}</div>
                    <button onClick={()=>actualizarMes(primerdiames, "suma")}>+</button>
                </div>
                <div className="dias-calendario"><div>DOM</div><div>LUN</div><div>MAR</div><div>MIE</div><div>JUE</div><div>VIE</div><div>SAB</div></div>
                <div className="dias-num-calendario">
                   
                      
                    {diasmeses[primerdiames.getMonth()].map((dia, index) => 
                       <button className="button" id={primerdiames.getFullYear()+"-"+(primerdiames.getMonth()+1)+"-"+dia} onClick={mostrarDatos}>{dia}</button>
                    )}
                </div>
            </div>
            <VentanaHabilitar actualizarCitas={actualizarCitas}  />
        </Fragment>
    )
    
}


///////////////////////////////////////////////////////////////////////////////////
const VentanaHabilitar = ({actualizarCitas}) => {
    
    return(
        <Fragment>
            <div className="ventana-habilitar">
                
                <div className="div-form" style={{display: 'none'}}>
                    <form className="form-citas" action="#" method="POST">

                        <input type="text" className="fechaid" name="fechaid" style={{display: 'none'}} onChange={actualizarCitas}/>

                        <input type="checkbox" className="diaIO" name="activo"  onChange={actualizarCitas} id="activo"/>
                        <label className="label" for="activo">IO</label>

                        <input type="checkbox" className="cita1" name="hora1" onChange={actualizarCitas} id="hora1"/>
                        <label className="label1" for="hora1">8:00 am</label>

                        <input type="checkbox" className="cita2" name="hora2" onChange={actualizarCitas} id="hora2"/>
                        <label className="label2" for="hora2">10:00 am</label>   

                        <input type="checkbox" className="cita3" name="hora3" onChange={actualizarCitas} id="hora3"/>
                        <label className="label3" for="hora3">12:00 pm</label>   

                        <input type="checkbox" className="cita4" name="hora4" onChange={actualizarCitas} id="hora4"/>
                        <label className="label4" for="hora4">2:00 pm</label>   

                        <input type="checkbox" className="cita5" name="hora5" onChange={actualizarCitas} id="hora5"/>
                        <label className="label5" for="hora5">4:00 pm</label>   

                        <input type="checkbox" className="cita6" name="hora6" onChange={actualizarCitas} id="hora6"/>
                        <label className="label6" for="hora6">6:00 pm</label>   

                        <input type="checkbox" className="cita7" name="hora7" onChange={actualizarCitas} id="hora7"/>
                        <label className="label7" for="hora7">8:00 pm</label>   
                    </form>
                </div>
                
            </div>
        </Fragment>
    )

}

export default Disponibilidad;