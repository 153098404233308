import { Fragment, useContext, useState } from "react"
import "../css/Qrcode.css"
import QRCode from "react-qr-code"
import { UsersContext } from "../context/UsersContext.jsx";


const Qrcode = () => {
    const localdir = "https://cliente.clickynail.com"
    //const localdir = window.location.origin+""
    const postDir = "/#/"
    const {userLogged} = useContext(UsersContext)
    const url = localdir + postDir + userLogged.link 

    const handleCopy = () => {
        navigator.clipboard.writeText(url)
    }
 
    return(
        <Fragment>
            <div className="qr-div">
                <QRCode
                className="qr"
                size={256}
                
                value={url}
                viewBox={`0 0 256 256`}
                />
                
            </div>
            <p className="indicacion-QR">Comparte el codigo QR o el siguiente enlace para que agenden una cita:</p>
            <div className="fondo-enlace-QR">
             <p className="linkcopy">{url}</p>
             <button className="btn-copy" onClick={handleCopy}>Copy</button>
            </div>
            
            
        </Fragment>
    )
}
export default Qrcode;