
import { Fragment } from 'react';
import './App.css';
import fondounas2 from './img/fondounas2.png';
import Direccionamiento from './componentes/Direccionamiento.jsx';
import FuncionesProvider from './context/FuncionesContext.jsx';
import ComponentesProvider from './context/ComponentesContext.jsx';
import UsersProvider from './context/UsersContext.jsx';
import Login from './componentes/Login.jsx';


function App() {
 

  return (
    <UsersProvider>
    <ComponentesProvider>
    <FuncionesProvider>
      <img src={fondounas2} className='imgFondo'></img>
    <div className='content'>
      <Direccionamiento/>
      
    </div>
    </FuncionesProvider>
    </ComponentesProvider>
    </UsersProvider>
    
    
  );
}

export default App;
