import { Fragment } from "react";

const Eliminacion = () => {

    return(
        <Fragment>
            <h1>Eliminacion de datos</h1>
        </Fragment>
    )
}

export default Eliminacion;