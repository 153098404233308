import { Fragment, useContext, useEffect, useState } from "react"
import "../css/Login.css";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { UsersContext } from "../context/UsersContext.jsx";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
    const urlfetch1 = process.env.REACT_APP_GUARDAR_NUEVO_ADMIN
    const urlfetch2 = process.env.REACT_APP_CREAR_ESRUCTURA
    const[loading, setLoading] = useState(true)
    const[nuevoRegistro, setNuevoRegistro] = useState()
    const[checkUserDupli, setCheckUserDupli] = useState()
    const[checkEmailDupli, setCheckEmailDupli] = useState()
    const{datosUsuariosAdmin, setDatosUsuariosAdmin, userLogged, setUserLogged, getUsuarios, loadingDUA} = useContext(UsersContext)
    const navigate = useNavigate()
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
    
   
    console.log(isAuthenticated)
    
    let existe
    const autenticar =() => {
        if(isAuthenticated){
            console.log("login ok")
            if(user.sub[0] === "f"){
                existe = datosUsuariosAdmin.filter(usuario => usuario.nombre_admin === user.name && usuario.tipo_login[0] === user.sub[0])
            }else{
                existe = datosUsuariosAdmin.filter(usuario => usuario.correo_admin === user.email && usuario.tipo_login[0] === user.sub[0])
            }
            console.log(existe)
            console.log(user.sub[0])
                if(existe.length){
                    //setLoading(!loading)
                    console.log("el usuario existe")
                    setUserLogged(existe[0])
                    console.log(existe[0].link)
                    navigate(`/${existe[0].link}/`)
                }else{
                    console.log("el usuario no existe")
                    setNuevoRegistro(user)
                    document.querySelector(".fondo-crear").style.display = "block"
                    if(user.sub[0] === "f"){
                        
                        document.querySelector(".label-correo").style.display = "block"
                        document.querySelector(".correo").style.display = "block"
                    }
                }
            
        }else{
            loginWithRedirect()
        }
    }


    
    const crearCuenta = (e) => {
        e.preventDefault()
        if(user.sub[0]=== "f" &&(document.querySelector(".nombre-cuenta").value === "" || document.querySelector(".correo").value === "")){
            swal({
                text: "Es necesario llenar todos los campos  para continuar"
            })
      
        }else if(user.sub[0] != "f" && document.querySelector(".nombre-cuenta").value === ""){
            swal({
                text: "Es necesario llenar nombre de la cuenta para continuar"
            })
        }else{
            let prospectoCuenta = document.querySelector(".nombre-cuenta").value
            let duplicadoCuenta = datosUsuariosAdmin.filter(usuario=> usuario.link == prospectoCuenta)
            let prospectoCorreo = document.querySelector(".correo").value
            let duplicadoCorreo = datosUsuariosAdmin.filter(usuario=> usuario.correo_admin == prospectoCorreo)
            if(user.sub[0] != "f" && duplicadoCuenta.length === 1){
                swal({
                    text: "El usuario "+prospectoCuenta+" ya existe, debes de cambiarlo"
                })
            }else if(user.sub[0] === "f" && duplicadoCorreo.length === 1){
                    swal({
                        text: "El correo "+prospectoCorreo+" ya existe, debes de cambiarlo"
                    })
                
            }else if(user.sub[0] === "f" && duplicadoCuenta.length === 1){
                    swal({
                        text: "El usuario "+prospectoCuenta+" ya existe, debes de cambiarlo"
                    })
            }else{
                 swal({
                    title:"Confirmar",
                    text: "Cofirmas que el nombre te tu cuenta sera: "+document.querySelector(".nombre-cuenta").value+" y el correo es: "+document.querySelector(".correo").value,
                    buttons: ["No", "Si"]
                }).then(res => {
                    if(res){
                        let formulario = document.querySelector(".form-nombre")
                        let datosform = new FormData(formulario)
                        datosform.set("id_login", nuevoRegistro.sub)
                        datosform.set("nombre_admin", nuevoRegistro.name)
                        if(user.sub[0] === "f"){
                            datosform.set("correo_admin", document.querySelector(".correo").value)
                        }else{
                            datosform.set("correo_admin", nuevoRegistro.email)
                        }
                        datosform.set("tipo_admin", "admin")
                        //datosform.set("base_de_datos", "clickyna_"+document.querySelector(".nombre-cuenta").value + nuevoRegistro.id)
                        datosform.set("link", document.querySelector(".nombre-cuenta").value)
                        datosform.set("img_url", nuevoRegistro.picture)
                        datosform.set("tipo_cuenta", "free")
                        datosform.set("tipo_login", nuevoRegistro.sub[0])
                        console.log(nuevoRegistro)

                        
                        fetch(urlfetch1, {
                            method: "POST",
                            body: datosform
                        })
                        .then(res => res.json())
                        .then(data => {
                            if(data == "hecho"){
                                console.log("se guardo el nuevo usuario")

                                
                                fetch(urlfetch2, {
                                    method: "POST",
                                    body:datosform
                                })
                                .then(res1 => res1.json())
                                .then(data1 => {
                                    if(data1 == "hecho"){
                                        console.log("se creo la estructura")
                                        document.querySelector(".fondo-crear").style.display = "none"
                                        getUsuarios()
                                        
                                        swal({
                                            text: "Tu cuenta "+prospectoCuenta+" se ha creado con exito"
                                        })
                                    }
                                })
                                
                                
                                
                            }
                        })


                    }
                })
            }
            
        }
    }
   
    const validarUsuario = () => {
        let prospecto = document.querySelector(".nombre-cuenta").value
        if(prospecto[prospecto.length-1] == " "){
            console.log("se ingreso un espacio")
            prospecto = prospecto.replace(" ","_")
            document.querySelector(".nombre-cuenta").value = prospecto
        }

        let duplicado = datosUsuariosAdmin.filter(usuario => usuario.link == prospecto)
        if(duplicado.length === 1){
            setCheckUserDupli(true)
        }else{
            setCheckUserDupli(false)
        }
    }
    const validarEmail = () => {
        let prospecto = document.querySelector(".correo").value
        if(prospecto[prospecto.length-1] == " "){
            console.log("se ingreso un espacio")
            prospecto = prospecto.replace(" ","")
            console.log(prospecto)
            document.querySelector(".nombre-cuenta").value = prospecto
        }
        let duplicado = datosUsuariosAdmin.filter(usuario => usuario.correo_admin == prospecto)
        if(duplicado.length === 1){
            setCheckEmailDupli(true)
        }else{
            setCheckEmailDupli(false)
        }
    }
    
    useEffect(() => {
        getUsuarios()
        if(isAuthenticated){
            autenticar()
        }
    },[isLoading, loadingDUA])

   
    return(
        <Fragment>
            <div className="fnd">
                {isLoading||loadingDUA?
                    <div className="fondo-cargando">
                      <p>Cargando...</p>
                    </div>:
                    <div className="fondo-iniciar">
                    <button className="btn" onClick={autenticar}>Iniciar sesion</button>
                    </div>
                }

                <div className="fondo-crear" style={{display:"none" }}>
                    <p>¡Aun no tienes cuenta en la aplicacion!</p>
                    <form className="form-nombre" action="#" method="POST" >
                        <p>Ingresa un nombre para tu cuenta:</p>
                        <input type="text" className="nombre-cuenta" name="nombre-cuenta" id="" onChange={validarUsuario} placeholder="Nombre de cuenta"/>
                        {checkUserDupli && <p className="msj-name">El nombre ya existe</p>}
                        <p style={{fontSize: "10px"}} >*Este nombre se usara para que tus clientes puedan acceder a tu cuenta</p>
                        <p style={{fontSize: "10px"}} >*No debe contener espacios en blanco ni caractedes especiales</p>
                        <hr />
                        <p className="label-correo" style={{display:"none"}}>Ingresa tu correo electronico:</p>
                        <input type="text" className="correo" name="correo" placeholder="Correo electronico" onChange={validarEmail} style={{display:"none"}}/>
                        {checkEmailDupli && <p className="msj-name">El correo ingresado ya esta registrado</p>}
                        <button className="btn" onClick={crearCuenta} >Registrarme</button>
                        {user && <p style={{fontSize: "10px"}}>Se creara tu cuenta de Clickynal usuando los datos de tu cuenta de {user.sub[0] === "g"? <p>gmail</p>:user.sub[0] === "f" ? <p>facebook</p>: <p>Apple</p> } <p style={{textDecoration:"underline", color:"blue" }} onClick={logout}>Cambiar cuenta</p></p>}
                    </form>
                </div>
            </div>

        </Fragment>
    )
    
    

    
}

export default Login;