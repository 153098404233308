import { createContext, useState } from "react";

export const FuncionesContext = createContext()

const FuncionesProvider = ({children}) => {
    

     //formatoFecha(.Date) funcion para formatear la fecha entra un .Date y la retorna en  "AAAA-MM-DD"
    const formatoFecha = (datos) => {
        let aniof = datos.getFullYear().toString()
        let mesf = (datos.getMonth()+1).toString()
        let diaf = datos.getDate().toString()
      
        if(mesf.length === 1){
            mesf = mesf.padStart(2, '0')  
        }
        if(diaf.length === 1){
            diaf = diaf.padStart(2, '0')   
        }
        return aniof+"-"+mesf+"-"+diaf
    }//////////////////////////////////////////////////////////////////////////////////////////formatoFecha


    return(
        <FuncionesContext.Provider value={{formatoFecha}}>{children}</FuncionesContext.Provider>
    )
}

export default FuncionesProvider;