import { Fragment, useContext, useEffect, useState } from "react";
import '../css/Fecha.css'
import atras from '../img/atras.svg'
import adelante from '../img/adelante.svg'
import Agendado from './Agendado.jsx'
import { FuncionesContext } from "../context/FuncionesContext.jsx";

const date = new Date()


function Fecha({link}){
    //const [fechahoy, setFechahoy] = useState(Date(date.getFullYear()+"-"+date.getMonth()+1+"-"+date.getDate()))
    const [fechahoy, setFechahoy] = useState(Date())
    let dia = new Date(fechahoy)
    const {formatoFecha} = useContext(FuncionesContext)
   
    
    //funcion que sirve para sumar o restar el dia de la fecha
    const actualizarDia = (hoy, operacion) =>{
        if(operacion === "suma"){
            hoy.setDate(hoy.getDate() + 1);
            setFechahoy(hoy)
            
        }else if(operacion === "resta"){
            hoy.setDate(hoy.getDate() - 1)
            setFechahoy(hoy) 
          
        }else{
            setFechahoy(hoy)
        } 
        
        document.querySelector(".fecha").value=formatoFecha(dia)
    }
    const actualizarDia2 = () => {
        let fecha2 = new Date(document.querySelector(".fecha").value)
        fecha2.setDate(fecha2.getDate() +1)
        console.log(fecha2)
        setFechahoy(fecha2)

    }
    
    useEffect(() => {
        actualizarDia(dia)
        
    },[])

    


    return(
        <Fragment>
            <div className="contenedorFecha">
                <img src={atras} className="flechaAtras"  onClick={() => {actualizarDia(dia, "resta")}}/> 
                <input type="date" className="fecha" name="fecha"  onChange={()=>{actualizarDia2()}} />
                <img src={adelante} className="flechaAdelante" onClick={() => {actualizarDia(dia, "suma")}} />   
            </div>
            <Agendado fechahoy={new Date(fechahoy)} link = {link}/>
        </Fragment>
    )
}

export default Fecha;
