import { createContext, useState } from "react";


export const UsersContext = createContext()

const UsersProvider = ({children}) => {
    const urlfetch = process.env.REACT_APP_CONSULTA_DIRECCIONES
    //const urlfetch = "http://localhost/citas/php/consultaDirecciones.php"
    const[datosUsuariosAdmin, setDatosUsuariosAdmin] = useState([])
    const[userLogged, setUserLogged] = useState([])
    const[loadingDUA, setLoadingDUA] = useState(true)


    const getUsuarios = () => {
       
        fetch(urlfetch)
        .then(res => res.json())
        .then(data => {
            setDatosUsuariosAdmin(data)
            setLoadingDUA(false)
        })
    }

    let global = {datosUsuariosAdmin, setDatosUsuariosAdmin, userLogged, setUserLogged, getUsuarios, loadingDUA}

    return(
        <UsersContext.Provider  value={global}>{children}</UsersContext.Provider>
    )

}
export default UsersProvider