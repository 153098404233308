import { useState, useEffect, Fragment} from 'react';
import '../css/Agendado.css'
import wpicon from '../img/wpicon.svg'

const date = new Date()
function Agendado({fechahoy, link}){
    const URL = process.env.REACT_APP_CONSULTA
    const [citas, setCitas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [citasfiltradas, setCitasfiltradas] = useState([{}])
    let whatsapp1 = "https://wa.me/"
    
    
    const consultarcitas = () => {
        
        let prefijo_tablas = new FormData()
        prefijo_tablas.set("link",link)
        fetch(URL, {
            method: 'POST',
            body: prefijo_tablas
        })
        .then(res => res.json())
        .then(data => {setCitas(data);
          setLoading(false);
        }) 
    }

    const actualizarFiltro = () => {
        //hace un filtro comparando la propiedad de cita.fecha de citas con con el fechahoy formateado mandado de el componente fecha
        setCitasfiltradas(citas.filter(cita => cita.fecha == (fechahoy.getFullYear()+"-"+((fechahoy.getMonth()+1).toString().padStart(2, '0'))+"-"+(fechahoy.getDate()).toString().padStart(2, '0')) &&cita.confirmado === "1"))
        
    }

    console.log(citasfiltradas)

    useEffect(() =>{
        consultarcitas();
        actualizarFiltro()
        
    },[fechahoy,loading]);


    return(
        <Fragment> 
  
            {citasfiltradas.map((cita, index) =>
                
                <div id={citasfiltradas[index].ID} className='fondoFicha'>
                <div id={citasfiltradas[index].ID} className='infoFicha'>
                    <h1 id={citasfiltradas[index].ID} >{citasfiltradas[index].nombre}</h1>
                    <p id={citasfiltradas[index].ID} >Hora: {citasfiltradas[index].hora}</p>
                    <p id={citasfiltradas[index].ID} > <a href={whatsapp1+citasfiltradas[index].wp}> <img className='wpicon' src={wpicon} />  WathsApp: {citasfiltradas[index].wp} </a> </p>
                   
                    </div>
                </div>
            )}
        </Fragment>
      
    )
}

export default Agendado;