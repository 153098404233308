import { Fragment, createContext, useEffect, useState } from "react";



export const ComponentesContext = createContext()

const ComponenestProvider = ({children}) => {


////Componente que muestra calendario con los dias para seleccioanar
    const Calendario = () => { 
        const date = new Date()
        //Calcula cuando el año es biciesto------------------------------------------------------------------------------------
        let feb
        let febn
        let calc = date.getFullYear() %4
        if(calc !== 0){
          feb=28
          febn=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]
        }else{
          feb=29
          febn=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]
        }
        const [primerdiames, setPrimerdiames] = useState(new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"1"))
        const [mesamostrar, setMesamostrar] = useState("")
        const [diadelasemana, setDiadelasemana] = useState(0)
        const[fechas , setFechas] = useState(["dia-11-2-2024", "dia-2-2-2024"])
        const treinta = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
        const treintayuno = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
        const diasmeses = [treintayuno,febn,treintayuno,treinta,treintayuno,treinta,treintayuno,treintayuno,treinta,treintayuno,treinta,treintayuno];
        let old =""
        
        //cambia el mes seleccionado
        const actualizarMes = (fecha1, operacion)=>{
            if(operacion === "suma"){
                fecha1.setMonth(fecha1.getMonth() + 1)
                setPrimerdiames(fecha1)
                console.log(primerdiames)
            }else if(operacion === "resta"){
                fecha1.setMonth(fecha1.getMonth() - 1)
                setPrimerdiames(fecha1)
                console.log(primerdiames)
            }
            mesNumeroLetra(primerdiames.getMonth())  
            actualizarDiadelasemana()
        }
        
        //debuelve el mes en letra de acuardo al numero pasado por la prop
        const mesNumeroLetra = (num) => {
            let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto","Septiembre","Octubre","Nobiembre","Diciembre"];
            setMesamostrar(meses[num])
        }

        //setea el dia de la semana en que empieza el mes
        const actualizarDiadelasemana = () => {
            setDiadelasemana(primerdiames.getDay()) 
        }

        //recorre el primer dia al dia de la semana correcto
        const recorrerDia = () => {
                document.querySelector("#dia-1-"+(primerdiames.getMonth()+1)+"-"+primerdiames.getFullYear()).setAttribute("class","d"+diadelasemana)
                document.querySelector("#dia-1-"+(primerdiames.getMonth()+1)+"-"+primerdiames.getFullYear()).classList.add("button")
            }

        //cambia el color del boton del dia seledted/unselected
        const selectedIO = (event) => {
            if(document.querySelector("#"+event.target.id).classList.contains("selected")){
                document.querySelector("#"+event.target.id).classList.replace("selected", "button")
            }else{
                document.querySelector("#"+event.target.id).classList.replace("button","selected")
            }
        }
        //cambia el color del boton del dia de acuerdo a lo guardado en la db
        const colorSeleccion = () =>{

            for(let x=0; x<fechas.length; x++){
                console.log(fechas[x])
                document.querySelector("#"+fechas[x]).classList.replace("button","selected")
            }
        }
        const holamundo = () =>{
            alert("hola mundo")
        }
       
        useEffect(()=>{ 
            actualizarDiadelasemana()
            actualizarMes(primerdiames, "nada")
            recorrerDia()
            colorSeleccion()
        },[diadelasemana])

        return(
            <Fragment>
                <div className="fondo-calendario">
                    <div className="botones-calendario">
                        <button onClick={()=>actualizarMes(primerdiames, "resta")}>-</button>
                        <div>{mesamostrar+" "+primerdiames.getFullYear()}</div>
                        <button onClick={()=>actualizarMes(primerdiames, "suma")}>+</button>
                    </div>
                    <div className="dias-calendario"><div>DOM</div><div>LUN</div><div>MAR</div><div>MIE</div><div>JUE</div><div>VIE</div><div>SAB</div></div>
                    <div className="dias-num-calendario">
                       
                          
                        {diasmeses[primerdiames.getMonth()].map((dia, index) => 
                           <button className="button" id={"dia-"+dia+"-"+(primerdiames.getMonth()+1)+"-"+primerdiames.getFullYear()} onClick={selectedIO}>{dia}</button>
                        )}

                    </div>
                </div>

            </Fragment>
        )
    }///////////////////////////////////////////////////////////Calendario

    return(
        <ComponentesContext.Provider value={{Calendario}}>{children}</ComponentesContext.Provider>
    )
}

export default ComponenestProvider;