import { Fragment, useState, useContext, useEffect} from "react";
import '../css/Menu.css'
import Fecha from "./Fecha";
import { Route, Routes, Link } from "react-router-dom";
import Disponibilidad from "./Disponibilidad";
import Qrcode from "./Qrcode.jsx";
import { UsersContext } from "../context/UsersContext.jsx";
import { useAuth0 } from "@auth0/auth0-react";


const Menu = ({nombre_admin, link}) =>{
    const [ioMenu, setIoMenu] = useState(false);
    const{userLogged} = useContext(UsersContext)
    const{ logout } = useAuth0()

    const accionMenu = () =>{
        setIoMenu(!ioMenu)
    }

  


    return(
        <Fragment>
            <div className="barra-menu">
                {!ioMenu ? 
                    <div className="btn-menu" onClick={accionMenu}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>:
                    <div className="btn-X" onClick={accionMenu}>X</div>
                }
                <div className="nombre-usuario">{nombre_admin}</div>
                <img className='img-usuario' src={userLogged.img_url} />
            </div>
            {ioMenu && <VentanaMenu accionMenu={accionMenu} logout={logout}/>}

                <Routes>
                    <Route  path="/" element={<Fecha link={link} />} />
                    <Route path="editar-espacios" element={<Disponibilidad link={link} />} />
                    <Route path="qr-code" element={<Qrcode/>} />
                </Routes>
        </Fragment>
    )
}

const VentanaMenu = ({accionMenu, logout}) =>{
    return(
        <Fragment>
            <div className="ventanaMenu">
            <hr></hr>
                <ul>   
                    <li onClick={accionMenu}><Link to='./'><h2>Agenda</h2></Link></li>
                    <li onClick={accionMenu}><Link to='./editar-espacios/'><h2>Editar Espacios</h2></Link></li> 
                    <li onClick={accionMenu}><Link to='./qr-code/'><h2>QR Code</h2></Link></li> 
                    <li onClick={logout}><h2>Cerrar Sesion</h2></li>  
                </ul>
            </div>
        </Fragment>
    )
}

export default Menu;