import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UsersContext } from "../context/UsersContext.jsx";

const ProtectedRoute = ({children , correo_admin}) => {
    const{userLogged} = useContext(UsersContext)
    
    console.log(userLogged)
    console.log(correo_admin)
    
    if(userLogged.correo_admin === correo_admin ){
        console.log("entro")
        return children
    }else{
        return <Navigate to="/"/>
    }


}

export default ProtectedRoute;